@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: Open Sans, sans-serif;
}

.w-100 {
  width: 100%;
}

.pt-30 {
  padding-top: 30px;
}

.mt-30 {
  margin-top: 30px;
}
