.logoContainer {
  color: white;
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: center;
}

.content {
  margin: 16px;
}

.textCenter {
  text-align: center;
}

.layout {
  min-height: 100vh;
  
}

.bgGradient {
  background: #36d1dc; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #36d1dc, #5b86e5); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #36d1dc, #5b86e5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.cardShadow {
  width: 460px;
  box-shadow: rgba(0, 0, 0, 0.25) 0 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.customHeaderLayout > header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
@media only screen and (max-width: 480px) {
	.content {
		margin: 0px;
	}
	.customSider > aside.customSiderAside{
		position: fixed ;
		left: 0 ;
		top: 0  ;
		width: 200px ;
		min-height: 100% ;
		z-index: 1000 ;
		overflow: auto ;
	}

	.customHeaderLayout > header > button.customTrigger {
		font-size: 16px;
		width: 64px;
		height: 64px;
		overflow: auto;
		position: sticky;
		z-index: 1000;
	}

}
